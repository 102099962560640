import Vue from "vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  filter: {
    CDN(listener) {
      let CND = "http://imow-upload.oss-cn-hangzhou.aliyuncs.com/";
      const isMock = /[0-9]+[x][0-9]+$/g;
      if (isMock.test(listener.src)) {
        CND = "https://dummyimage.com/";
      }
      listener.src = CND + listener.src;
    }
  }
});

export default VueLazyload;
