import { post } from "@/util/request.js";

async function getHotPoint(data) {
  try {
    const res = await post("getHotPoint", data);
    return res.data;
  } catch (error) {
    console.log(error);
    return { hotPointList: [] };
  }
}

export default getHotPoint;
