import { get } from "@/util/request.js";

async function getNavBar(params) {
  try {
    const res = await get("getNavBar", params);
    return res.data;
  } catch (error) {
    console.log(error);
    return { navBarList: [] };
  }
}

export { getNavBar };
