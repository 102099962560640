import Vue from "vue";

Vue.filter("money", function(val) {
  //保留两位小数 不
  var toFixedNum = Number(val).toFixed(3);
  var realVal = toFixedNum.substring(0, toFixedNum.toString().length - 1);
  return realVal;
});

Vue.filter("toTenThousand", function(val) {
  return val / 10000;
});
