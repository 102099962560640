<template>
  <div class="header-bar">
    <div class="header-bar-item">
      Hi，欢迎光临阿母工业
      <span :class="{'user-name-color': userName}" class="sp">{{ $store.state.user.isLogin? $store.state.user.userInfo.name: "未登录" }}</span>
      <span v-if="$store.state.user.isLogin" class="sp user-name-color hander" @click="loginOut">退出</span>
      <!-- <a class="sp" href="">加入服务店</a>
      <a class="sp" href="">加入工厂店</a> -->
      <div class="header-bar-item-right">
        <template v-if="$store.state.user.isLogin">
          <nuxt-link :to="{ path: '/myOrder'}">
          <img style="vertical-align:-1px;margin-right:3px;" src="@/assets/icon/my_im.png" alt="我的阿母">我的阿母</nuxt-link>
          <nuxt-link :to="{ path: '/orderCart'}">
          <img style="vertical-align:-2px;margin-right:3px;" src="@/assets/icon/shopping_car.png" alt="我的购物车">我的购物车</nuxt-link>
        </template>
        <template v-else>
          <nuxt-link :to="{ path: '/login'}" class="user-name-color">去登陆</nuxt-link>
        </template>
        <a href="https://jobs.51job.com/all/co6365232.html" target="_blank">人才招聘</a>
        <!-- <a href="">帮助中心<i/></a> -->
        服务热线：400-051-2525&nbsp;|
        <a href="http://www.ep-zl.com" target="_blank">中力<img style="vertical-align: -7px;margin-left:3px;" src="@/assets/icon/EP_logo.png" alt="中力"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { loginOut } from "@/api/login";

export default {
  data() {
    return {
      userName: "我是用户名"
    };
  },
  methods: {
    async loginOut() {
      await loginOut();
      this.$store.commit("loginout");
    }
  }
};
</script>

<style scoped>
.hander {
  cursor: pointer;
}
/* 左侧部分间距控制 */
.sp {
  margin-left: 15px;
}
/* 用户名的颜色 */
.user-name-color,
.header-bar-item-right a.user-name-color {
  color: red;
}
/* 头部导航 */
.header-bar,
.header-bar a {
  color: #262626;
}
.header-bar {
  height: 35px;
  line-height: 35px;
  background-color: #f2f2f2;
  font-size: 12px;
}

.header-bar-item {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* 头部导航右侧 */
.header-bar-item-right {
  position: absolute;
  right: 0px;
  height: 35px;
  top: 0px;
}

.header-bar-item-right a {
  margin-right: 10px;
}
</style>
