import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _284f2519 = () => interopDefault(import('..\\pages\\629\\index.vue' /* webpackChunkName: "pages_629_index" */))
const _2415f534 = () => interopDefault(import('..\\pages\\activeOrderConfirm\\index.vue' /* webpackChunkName: "pages_activeOrderConfirm_index" */))
const _66843296 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages_login" */))
const _556e1418 = () => interopDefault(import('..\\pages\\myOrder\\index.vue' /* webpackChunkName: "pages_myOrder_index" */))
const _efa1ce30 = () => interopDefault(import('..\\pages\\orderCart\\index.vue' /* webpackChunkName: "pages_orderCart_index" */))
const _2399aa4c = () => interopDefault(import('..\\pages\\orderConfirm\\index.vue' /* webpackChunkName: "pages_orderConfirm_index" */))
const _0e2b51a6 = () => interopDefault(import('..\\pages\\privacyPolicy.vue' /* webpackChunkName: "pages_privacyPolicy" */))
const _0ae9acb3 = () => interopDefault(import('..\\pages\\promotion\\index.vue' /* webpackChunkName: "pages_promotion_index" */))
const _1f79b576 = () => interopDefault(import('..\\pages\\search\\index.vue' /* webpackChunkName: "pages_search_index" */))
const _0b2ed4c5 = () => interopDefault(import('..\\pages\\secondHandCar\\index.vue' /* webpackChunkName: "pages_secondHandCar_index" */))
const _3029889a = () => interopDefault(import('..\\pages\\sellOrder\\index.vue' /* webpackChunkName: "pages_sellOrder_index" */))
const _6ff2a736 = () => interopDefault(import('..\\pages\\21629\\online\\index.vue' /* webpackChunkName: "pages_21629_online_index" */))
const _541d7435 = () => interopDefault(import('..\\pages\\629\\rank.vue' /* webpackChunkName: "pages_629_rank" */))
const _24a5cb11 = () => interopDefault(import('..\\pages\\memberCenter\\address.vue' /* webpackChunkName: "pages_memberCenter_address" */))
const _b7320ad6 = () => interopDefault(import('..\\pages\\memberCenter\\historyOrder\\index.vue' /* webpackChunkName: "pages_memberCenter_historyOrder_index" */))
const _2c3fbc3d = () => interopDefault(import('..\\pages\\memberCenter\\personalData.vue' /* webpackChunkName: "pages_memberCenter_personalData" */))
const _9d2fc510 = () => interopDefault(import('..\\pages\\password\\find.vue' /* webpackChunkName: "pages_password_find" */))
const _ea9a9ac4 = () => interopDefault(import('..\\pages\\password\\findPayment.vue' /* webpackChunkName: "pages_password_findPayment" */))
const _78c06a39 = () => interopDefault(import('..\\pages\\password\\modify.vue' /* webpackChunkName: "pages_password_modify" */))
const _7a6fbabd = () => interopDefault(import('..\\pages\\password\\modifyPayment.vue' /* webpackChunkName: "pages_password_modifyPayment" */))
const _f141a4be = () => interopDefault(import('..\\pages\\promotion\\policy.vue' /* webpackChunkName: "pages_promotion_policy" */))
const _a3f9b726 = () => interopDefault(import('..\\pages\\promotion\\purchasing.vue' /* webpackChunkName: "pages_promotion_purchasing" */))
const _6188fe02 = () => interopDefault(import('..\\pages\\search\\shop.vue' /* webpackChunkName: "pages_search_shop" */))
const _35c91583 = () => interopDefault(import('..\\pages\\sellOrder\\partialShipment.vue' /* webpackChunkName: "pages_sellOrder_partialShipment" */))
const _1ac81084 = () => interopDefault(import('..\\pages\\memberCenter\\property\\balance.vue' /* webpackChunkName: "pages_memberCenter_property_balance" */))
const _369d68ae = () => interopDefault(import('..\\pages\\memberCenter\\property\\coupon.vue' /* webpackChunkName: "pages_memberCenter_property_coupon" */))
const _3f491139 = () => interopDefault(import('..\\pages\\memberCenter\\property\\couponTransfer.vue' /* webpackChunkName: "pages_memberCenter_property_couponTransfer" */))
const _1fbe8752 = () => interopDefault(import('..\\pages\\memberCenter\\property\\exchangeBill.vue' /* webpackChunkName: "pages_memberCenter_property_exchangeBill" */))
const _05415455 = () => interopDefault(import('..\\pages\\memberCenter\\property\\invoice.vue' /* webpackChunkName: "pages_memberCenter_property_invoice" */))
const _0e13edcc = () => interopDefault(import('..\\pages\\memberCenter\\property\\myIMB.vue' /* webpackChunkName: "pages_memberCenter_property_myIMB" */))
const _29449ed5 = () => interopDefault(import('..\\pages\\memberCenter\\batchDeliver\\applyBatchDeliver\\_orderId.vue' /* webpackChunkName: "pages_memberCenter_batchDeliver_applyBatchDeliver__orderId" */))
const _a3dbf58a = () => interopDefault(import('..\\pages\\memberCenter\\batchDeliver\\detail\\_id.vue' /* webpackChunkName: "pages_memberCenter_batchDeliver_detail__id" */))
const _8bc333cc = () => interopDefault(import('..\\pages\\memberCenter\\batchDeliver\\orderConfirm\\_orderId.vue' /* webpackChunkName: "pages_memberCenter_batchDeliver_orderConfirm__orderId" */))
const _7283d51a = () => interopDefault(import('..\\pages\\memberCenter\\batchDeliver\\payment\\_batch.vue' /* webpackChunkName: "pages_memberCenter_batchDeliver_payment__batch" */))
const _47a90ff0 = () => interopDefault(import('..\\pages\\memberCenter\\batchDeliver\\success\\_batch.vue' /* webpackChunkName: "pages_memberCenter_batchDeliver_success__batch" */))
const _a22b8ae8 = () => interopDefault(import('..\\pages\\memberCenter\\historyOrder\\detail\\_id.vue' /* webpackChunkName: "pages_memberCenter_historyOrder_detail__id" */))
const _57dde80c = () => interopDefault(import('..\\pages\\memberCenter\\myOrder\\detail\\_id.vue' /* webpackChunkName: "pages_memberCenter_myOrder_detail__id" */))
const _00b516ab = () => interopDefault(import('..\\pages\\payment\\success\\_orderId.vue' /* webpackChunkName: "pages_payment_success__orderId" */))
const _e52bef8c = () => interopDefault(import('..\\pages\\product\\package\\_promotionId.vue' /* webpackChunkName: "pages_product_package__promotionId" */))
const _d1d570c8 = () => interopDefault(import('..\\pages\\payment\\_orderId.vue' /* webpackChunkName: "pages_payment__orderId" */))
const _2ec42fe2 = () => interopDefault(import('..\\pages\\product\\_id.vue' /* webpackChunkName: "pages_product__id" */))
const _0316439e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/629",
    component: _284f2519,
    name: "629"
  }, {
    path: "/activeOrderConfirm",
    component: _2415f534,
    name: "activeOrderConfirm"
  }, {
    path: "/login",
    component: _66843296,
    name: "login"
  }, {
    path: "/myOrder",
    component: _556e1418,
    name: "myOrder"
  }, {
    path: "/orderCart",
    component: _efa1ce30,
    name: "orderCart"
  }, {
    path: "/orderConfirm",
    component: _2399aa4c,
    name: "orderConfirm"
  }, {
    path: "/privacyPolicy",
    component: _0e2b51a6,
    name: "privacyPolicy"
  }, {
    path: "/promotion",
    component: _0ae9acb3,
    name: "promotion"
  }, {
    path: "/search",
    component: _1f79b576,
    name: "search"
  }, {
    path: "/secondHandCar",
    component: _0b2ed4c5,
    name: "secondHandCar"
  }, {
    path: "/sellOrder",
    component: _3029889a,
    name: "sellOrder"
  }, {
    path: "/21629/online",
    component: _6ff2a736,
    name: "21629-online"
  }, {
    path: "/629/rank",
    component: _541d7435,
    name: "629-rank"
  }, {
    path: "/memberCenter/address",
    component: _24a5cb11,
    name: "memberCenter-address"
  }, {
    path: "/memberCenter/historyOrder",
    component: _b7320ad6,
    name: "memberCenter-historyOrder"
  }, {
    path: "/memberCenter/personalData",
    component: _2c3fbc3d,
    name: "memberCenter-personalData"
  }, {
    path: "/password/find",
    component: _9d2fc510,
    name: "password-find"
  }, {
    path: "/password/findPayment",
    component: _ea9a9ac4,
    name: "password-findPayment"
  }, {
    path: "/password/modify",
    component: _78c06a39,
    name: "password-modify"
  }, {
    path: "/password/modifyPayment",
    component: _7a6fbabd,
    name: "password-modifyPayment"
  }, {
    path: "/promotion/policy",
    component: _f141a4be,
    name: "promotion-policy"
  }, {
    path: "/promotion/purchasing",
    component: _a3f9b726,
    name: "promotion-purchasing"
  }, {
    path: "/search/shop",
    component: _6188fe02,
    name: "search-shop"
  }, {
    path: "/sellOrder/partialShipment",
    component: _35c91583,
    name: "sellOrder-partialShipment"
  }, {
    path: "/memberCenter/property/balance",
    component: _1ac81084,
    name: "memberCenter-property-balance"
  }, {
    path: "/memberCenter/property/coupon",
    component: _369d68ae,
    name: "memberCenter-property-coupon"
  }, {
    path: "/memberCenter/property/couponTransfer",
    component: _3f491139,
    name: "memberCenter-property-couponTransfer"
  }, {
    path: "/memberCenter/property/exchangeBill",
    component: _1fbe8752,
    name: "memberCenter-property-exchangeBill"
  }, {
    path: "/memberCenter/property/invoice",
    component: _05415455,
    name: "memberCenter-property-invoice"
  }, {
    path: "/memberCenter/property/myIMB",
    component: _0e13edcc,
    name: "memberCenter-property-myIMB"
  }, {
    path: "/memberCenter/batchDeliver/applyBatchDeliver/:orderId?",
    component: _29449ed5,
    name: "memberCenter-batchDeliver-applyBatchDeliver-orderId"
  }, {
    path: "/memberCenter/batchDeliver/detail/:id?",
    component: _a3dbf58a,
    name: "memberCenter-batchDeliver-detail-id"
  }, {
    path: "/memberCenter/batchDeliver/orderConfirm/:orderId?",
    component: _8bc333cc,
    name: "memberCenter-batchDeliver-orderConfirm-orderId"
  }, {
    path: "/memberCenter/batchDeliver/payment/:batch?",
    component: _7283d51a,
    name: "memberCenter-batchDeliver-payment-batch"
  }, {
    path: "/memberCenter/batchDeliver/success/:batch?",
    component: _47a90ff0,
    name: "memberCenter-batchDeliver-success-batch"
  }, {
    path: "/memberCenter/historyOrder/detail/:id?",
    component: _a22b8ae8,
    name: "memberCenter-historyOrder-detail-id"
  }, {
    path: "/memberCenter/myOrder/detail/:id?",
    component: _57dde80c,
    name: "memberCenter-myOrder-detail-id"
  }, {
    path: "/payment/success/:orderId?",
    component: _00b516ab,
    name: "payment-success-orderId"
  }, {
    path: "/product/package/:promotionId?",
    component: _e52bef8c,
    name: "product-package-promotionId"
  }, {
    path: "/payment/:orderId?",
    component: _d1d570c8,
    name: "payment-orderId"
  }, {
    path: "/product/:id?",
    component: _2ec42fe2,
    name: "product-id"
  }, {
    path: "/",
    component: _0316439e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
