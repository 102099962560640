<template>
  <div class="header">
    <HeaderBar />
    <!-- <a href="http://www.imow.cn/1111" class="header-img-s" /> -->
    <div class="search">
      <router-link to="/">
        <img
          class="logo"
          src="@/assets/images/logo.png"
          alt="logo"
        >
      </router-link>
      <div v-if="searchIsShow" class="search-body">
        <div>
          <div class="search-nav">
            <span :class="{ active: searchFlag }">产品</span>
            <!-- <span :class="{ active: searchFlag }" @click="searchSwitch">产品</span> -->
            <!-- <span :class="{ active: !searchFlag }" @click="searchSwitch">店铺</span> -->
          </div>
          <input v-model="searchContent" type="text" placeholder="搜索商品">
          <span class="search-btn" @click="search">
          <img class="search" src="@/assets/icon/search.png" alt="">搜索</span>
          <div class="hot-link">
            <a v-for="(item,index) in hotProduct" :key="index" :class="{ 'hot':item.isHot }" href="javascript:void(0);" @click="handleClick(index)">{{ item.text }}</a>
          </div>
        </div>
        <router-link :to="adLink">
          <img class="ad" :src="adImg" alt="广告图片">
        </router-link>
      </div>
      <div v-else class="no-login" @click="goLogin">
        <img src="~assets/imow2.0/nologin-defalut.png" alt="" class="no-login-img">
      </div>
    </div>
    <div v-if="!factory" class="header-nav">
      <div class="header-nav">
        <div style="cursor: default;">
          <span>&nbsp;&nbsp;&nbsp;全部产品分类
            <ul v-show="isIndex" v-if="headerNav" id="nav-span-item" @mousemove="setRenderMidNavPanel">
              <li v-for="(item,index) in headerNav" :key="index+'a'" @click="Jump(item.id)">
                <h4>
                <a :href="'/search?category0=' + item.id" class="main-title">{{ item.title }}</a> <img src="@/assets/icon/arrow_left.png" alt=">"> </h4>
                <!-- <div class="nav-span-item-child">
                  <template v-for="(child, index2) in item.child">
                    <nuxt-link v-if="index2 < 3" :key="index2+'b'" :to="`/search?category0=${item.id}&category1=${child.id}`">{{ child.title }}</nuxt-link>
                  </template>
                </div> -->
                <ul v-if="renderMidNavPanel" class="middle-nav-pane">
                  <li v-for="(child, index3) in item.child" :key="index3+'c'">
                    <span>
                      <a :href="`/search?category0=${item.id}&category1=${child.id}`">{{ child.title }}</a>
                    </span>
                    <a v-for="(innerChild, index4) in child.child" :key="index4+'d'" :href="`/search?category0=${item.id}&category1=${child.id}&category2=${innerChild.id}`">{{ innerChild.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </span>
          <ul v-if="navLimtShow" class="nav-bar">
            <li v-for="(item, index) in navBarList" :key="index">
              <router-link :class="pathname === item.link ? 'active': '' " :to="item.link">
                <img v-if="item.navValue == 1" :src="'https://imow-upload.oss-cn-hangzhou.aliyuncs.com/'+item.navUrl" alt="工厂店">
                <span v-else>{{ item.txt }}</span>
              </router-link>
            </li>

          </ul>
          <ul v-else class="nav-bar">
            <!-- <li>
              <router-link :class="pathname === '/IMServiceShop' ? 'active': '' " to="/IMServiceShop">阿母服务店</router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "./headerBar/HeaderBar.vue";

import getHotPoint from "@/api/index/getHotPoint.js";
import getAllProductNav from "@/api/index/getAllProductNav.js";
import * as Index from "@/api/index/index.js";
import {
  eventBus,
  DOSEARCH,
  DOSHOPSEARCH,
  HEADERSETKEYWORD,
  SHOWCATRGORYBAT,
  HIDECATRGORYBAT,
  SEARCHSWITCHTOPRODUCT,
  SEARCHSWITCHTOSHOP
} from "@/util/eventBus";
export default {
  components: {
    HeaderBar
  },
  data() {
    return {
      islogin: true,
      userName: "我是用户名",
      hotProduct: [],
      searchFlag: true,
      searchContent: "",
      headerNav: false,
      factory: false,
      isIndex: false,
      pathname: "",
      navBarList: "",
      adLink: "",
      adImg: "",
      renderMidNavPanel: false
    };
  },
  computed: {
    searchIsShow() {
      return this.$store.state.user.isLogin ? true : false;
    },
    navLimtShow() {
      return this.$store.state.user.isLogin ? true : false;
    }
  },
  // memory-leak
  mounted() {
    let that = this;
    eventBus.$on("getNavBar", function() {
      eventBus.$emit("navBar", that.navBarList);
    });
    eventBus.$on(SHOWCATRGORYBAT, this.showBar);
    eventBus.$on(HIDECATRGORYBAT, this.hideBar);
    eventBus.$on(HEADERSETKEYWORD, this.setKeyWord);
    eventBus.$on(SEARCHSWITCHTOPRODUCT, this.switchToProduct);
    eventBus.$on(SEARCHSWITCHTOSHOP, this.switchToShop);

    getHotPoint().then(hotAndAdData => {
      //搜索框下方的热点获取
      let hotAndAd = hotAndAdData;
      this.hotProduct = hotAndAd.hotProduct;
      this.adImg =
        "https://imow-upload.oss-cn-hangzhou.aliyuncs.com/" + hotAndAd.adSrc;
      this.adLink = hotAndAd.link;
    });
    //获取所有产品分类的导航
    getAllProductNav().then(res => {
      this.headerNav = res.headerNav;
    });

    Index.getNavBar().then(navBarListData => {
      let res = navBarListData.navBarList;
      this.navBarList = res;
      eventBus.$emit("navBar", res);
    });

    let tmp = window.location.pathname;
    if (tmp.indexOf("factory") !== -1) {
      this.factory = true;
    }
    if (window.location.pathname === "/") {
      this.isIndex = true;
    }

    if (tmp.indexOf("IMfactoryShop") !== -1) {
      this.factory = false;
    }

    this.pathname = tmp;
  },
  methods: {
    Jump(id) {
      this.$router.push(`/search?category0=${id}`);
    },
    setRenderMidNavPanel() {
      if (!this.renderMidNavPanel) {
        this.renderMidNavPanel = true;
      }
    },
    hideBar() {
      this.isIndex = false;
    },
    showBar() {
      this.isIndex = true;
    },
    setKeyWord(keyWord) {
      this.searchContent = keyWord;
    },
    searchSwitch() {
      this.searchFlag = !this.searchFlag;
    },
    switchToProduct() {
      this.searchFlag = true;
    },
    switchToShop() {
      this.searchFlag = false;
    },
    search() {
      if (this.searchFlag) {
        this.gotoSearch(this.searchContent);
      } else {
        this.gotoShopSearch(this.searchContent);
      }
    },
    //热点按钮的点击事件
    handleClick(index) {
      let keyWord = this.hotProduct[index].text;
      this.searchContent = keyWord;
      this.gotoSearch(keyWord);
    },
    gotoSearch(keyWord) {
      let currentPath = this.$router.history.current.path;
      if (currentPath === "/search") {
        eventBus.$emit(DOSEARCH, {
          keyWord,
          PageIndex: 1,
          done: () => {}
        });
      } else {
        this.$router.push({ path: "/search", query: { keyWord: keyWord } });
      }
    },
    gotoShopSearch(keyWord) {
      let currentPath = this.$router.history.current.path;
      if (currentPath === "/search/shop") {
        eventBus.$emit(DOSHOPSEARCH, {
          keyWord,
          done: () => {}
        });
      } else {
        this.$router.push({
          path: "/search/shop",
          query: { keyWord: keyWord }
        });
      }
    },
    goLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
.header-img-s {
  display: block;
  width: 100%;
  height: 120px;
  background-image: url("~assets/images/1111/banner-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
/* 搜索框 */
.search {
  height: 135px;
  width: 1200px;
  margin: 0 auto;
}

.search .logo {
  margin-top: 10px;
}

.search-body {
  float: right;
  margin-right: 15px;
  width: 890px;
}

.no-login {
  float: right;
  padding-top: 30px;
}

.no-login-img {
  cursor: pointer;
}
.search-nav span {
  width: 45px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 12px;
  color: #a9a9a9;
  /* color: white; */
  margin-left: 10px;
  cursor: pointer;
}

.search-nav .active {
  background-color: #d70010;
  color: white;
}

.search-body {
  position: relative;
}

.search-body input {
  width: 505px;
  height: 37px;
  border: 2px solid #d70010;
  outline: none;
  padding-left: 15px;
}

/* 搜索框placeholder颜色 */
::-webkit-input-placeholder {
  color: #a9a9a9 !important;
}

:-moz-placeholder {
  color: #a9a9a9 !important;
}

::-moz-placeholder {
  color: #a9a9a9 !important;
}

:-ms-input-placeholder {
  color: #a9a9a9 !important;
}

.search-body > div {
  position: relative;
  height: 41px;
  margin-top: 17px;
}

.search-body .ad {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 200px;
  max-height: 80px;
}

.search-btn {
  width: 134px;
  line-height: 41px;
  background-color: #d70010;
  display: inline-block;
  /* vertical-align: -1px; */
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-left: -10px;
  vertical-align: top;
}

.search-btn .search {
  width: 30px;
  height: 30px;
  vertical-align: -8px;
  margin-left: 20px;
  margin-right: 15px;
}

.hot-link {
  margin-top: 5px;
  font-size: 12px;
}

.hot-link a {
  margin-left: 5px;
  color: #a9a9a9;
}

/* 搜索框下方hot颜色 */
.hot-link .hot {
  color: red;
}

/* 底部导航栏 */
.header-nav {
  height: 34px;
  border-bottom: 3px solid #d70010;
  cursor: pointer;
}

.header-nav > div {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.header-nav > div > span {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  width: 200px;
  background-color: #d70010;
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: relative;
}

.header-nav > div > span .nav {
  background-color: #333333;
}

.header-nav > div > span .nav > ul {
  height: 480px;
  padding-top: 20px;
}

.header-nav > div > span .nav h4 {
  font-size: 16px;
  color: white;
  height: 25px;
  line-height: 25px;
}

.nav-item-a {
  overflow: hidden;
  height: 20px;
}

/* 右侧浮动 */
.right {
  float: right;
}

.header-nav .nav-bar {
  position: absolute;
  top: 0px;
  left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 940px;
  align-items: center;
}

.header-nav .nav-bar li {
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  font-size: 16px;
  margin: 0 40px;
}

.header-nav .nav-bar li:hover {
  z-index: 2;
}

.header-nav .nav-bar li:hover a {
  color: #d7000f;
}

.header-nav .nav-bar li a.active {
  color: #d7000f;
}

.header-nav .nav-bar li img {
  vertical-align: -6px;
}

.nav-bar li a {
  color: #161616;
}

.header-nav .nav-bar .unlogin {
  margin-left: 98px;
}

.header-nav .nav-bar .unlogin img {
  vertical-align: -6px;
}

.nav-bar .unlogin:first-child {
  margin-left: 90px;
}

/* hover导航自内容 */
#nav-span-item {
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.55);
  width: 200px;
  height: 477px;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 10px;
}

#nav-span-item li {
  min-height: 30px;
  padding: 5px 15px;
  cursor: pointer;
}

#nav-span-item li img {
  float: right;
  margin-top: 3px;
  margin-right: 3px;
}

#nav-span-item > li:hover,
#nav-span-item > li:hover a.main-title {
  background-color: #000000;
  color: red !important;
}

a.main-title {
  color: white;
}

#nav-span-item > li:hover .middle-nav-pane {
  z-index: 100;
  display: block;
}

#nav-span-item h4 {
  font-size: 16px;
  line-height: 20px;
  padding-top: 2px;
}
#nav-span-item .nav-span-item-child {
  font-size: 10px;
  height: 15px;
  line-height: 18px;
  overflow: hidden;
}

#nav-span-item .nav-span-item-child a {
  color: #a9a9a9;
  font-weight: normal;
  margin-right: 5px;
}

.header-nav > div > span:hover #nav-span-item {
  display: block !important;
}

.middle-nav-pane {
  position: absolute;
  width: 1000px;
  left: 200px;
  top: 3px;
  background: #ffffff;
}

#nav-span-item .middle-nav-pane li {
  padding-left: 150px;
  position: relative;
}

#nav-span-item .middle-nav-pane li span:hover,
#nav-span-item .middle-nav-pane li a:hover {
  color: red;
}

.middle-nav-pane {
  min-height: 500px;
  display: none;
}

.middle-nav-pane span {
  display: inline-block;
  position: absolute;
  left: 45px;
  width: 120px;
  font-size: 12px;
  color: #000000;
}

.middle-nav-pane span > a {
  color: #000;
}

.middle-nav-pane a {
  display: inline-block;
  border-left: 1px solid black;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  line-height: 15px;
  color: #848484;
}
/* .header-nav > div > span .nav li {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  height: 45px;
  line-height: 0.9;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.header-nav > div > span .nav > ul > li:hover {
  background-color: #000000;
}

.header-nav > div > span .nav li:first-child {
  margin-top: 0px;
}

.header-nav > div > span .nav li a {
  font-size: 10px;
  color: #8b8b8b;
} */
</style>
