// import axios from "axios";

// export default function(ctx) {
//   if (!ctx.axiosinterceptors) {
//     let axiosinterceptors = axios.interceptors.request.use(
//       function(config) {
//         if (ctx.store.state.user.isLogin) {
//           config.headers = {
//             Authorization: `${ctx.store.state.user.userInfo.token}`
//           };
//         }

//         return config;
//       },
//       function(error) {
//         return Promise.reject(error);
//       }
//     );
//     if (ctx.req) {
//       ctx.req.eject = function() {
//         axios.interceptors.request.eject(axiosinterceptors);
//       };
//     }
//   }
// }

import Vue from "vue";
import axios from "axios";

function authPlugin(Vue) {
  Vue.mixin({
    created: function() {
      if (
        this.$store &&
        this.$store.state.user.isLogin &&
        !axios.defaults.headers.common["Authorization"]
      ) {
        axios.defaults.headers.common[
          "Authorization"
        ] = this.$store.state.user.userInfo.token;
      }
    }
  });
}

Vue.use(authPlugin);

// export default authPlugin;
