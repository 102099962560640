<template>
  <div class="footer">
    <div class="footer-body">
      <div class="footer-object">
        <div>
          <img
            src="~/assets/images/foot-mind-logo.png"
            alt=""
          >
        </div>
        <h4 class="footer-object-desc">最后一公里服务店</h4>
        <span>就在客户身边</span>
      </div>
      <div class="footer-object">
        <div>
          <img
            src="~/assets/images/foot-conv-logo.png"
            alt=""
          >
        </div>
        <h4 class="footer-object-desc">网上下单</h4>
        <span>简单方便</span>
      </div>
      <div class="footer-object">
        <div>
          <img
            src="~/assets/images/foot-major-logo.png"
            alt=""
          >
        </div>
        <h4 class="footer-object-desc">专注多品类工业品</h4>
        <span>为企业提高作业效率<br>改善作业环境</span>
      </div>
    </div>
    <div class="footer-body-list">
      <ul class="footer-list">
        <li class="list-li-first">用户管理</li>
        <li>如何注册</li>
        <li><router-link to="/password/find">忘记密码</router-link></li>
      </ul>
      <ul class="footer-list">
        <li class="list-li-first">交易流程</li>
        <li>如何订购</li>
        <li>如何快速下单</li>
        <li>如何快速询价</li>
        <li>如何支付</li>
      </ul>
      <ul class="footer-list">
        <li class="list-li-first">配送方式</li>
        <li>快递配送</li>
        <li>物流配送</li>
        <li>其它</li>
      </ul>
      <ul class="footer-list">
        <li class="list-li-first">售后服务</li>
        <li>售后服务承诺</li>
        <li>退货总则</li>
        <li>关于发票</li>
      </ul>
      <ul class="footer-list">
        <!-- <li class="list-li-first"><router-link to="/aboutIM">关于我们</router-link></li> -->
        <!-- <li><a href="http://p.qiao.baidu.com/cps/chat?siteId=11192881&amp;userId=18113342" target="_blank">联系我们</a></li> -->
        <li class="list-li-first"><a href="/privacyPolicy" target="_blank">隐私政策</a></li>
      </ul>
    </div>
    <p> <br>
      <span v-if="host==='imovv'"><a target="_blank" class="bn" href="https://beian.miit.gov.cn">浙ICP备18042928号-1</a> <br></span>
      <span v-if="host === '.com.cn'"><a target="_blank" class="bn" href="https://beian.miit.gov.cn">浙ICP备18042928号-3</a> <br><a target="_blank" class="bn" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003350">浙公网安备 33010302003350号</a></span>
      <span v-if="host === ''"><a target="_blank" class="bn" href="https://beian.miit.gov.cn">浙ICP备18042928号-2</a>  <br>
      <!-- 增值电信业务经营许可证：浙B2-20190245 <br> -->
      <a target="_blank" class="bn" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003351">浙公网安备 33010302003351号</a></span>
      <br>
      阿母工业 400-051-2525</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      host: ""
    };
  },
  mounted() {
    if (window.location.href.includes(".com.cn")) {
      this.host = ".com.cn";
    }
    if (window.location.href.includes("imovv.com")) {
      this.host = "imovv";
    }
  }
};
</script>

<style scoped>
* {
  font-family: "Microsoft YaHei";
}
.footer {
  margin: 0 auto;
  width: 1200px;
}

.footer p {
  width: 450px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 2;
  text-align: center;
  color: #636363;
  margin-bottom: 60px;
}

.footer-body {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #c9c9c9;
  display: flex;
  justify-content: center;
}

.footer-object {
  margin: 27px 80px;
  margin-bottom: 55px;
  text-align: center;
}

.footer-object-desc {
  line-height: 1.5;
}

.footer-body-list {
  margin: 0 auto;
  padding: 0 100px;
  color: #636363;
  display: flex;
  justify-content: space-evenly;
}

.footer-body-list a {
  color: #636363;
}

.footer-list {
  width: 20%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
}

.list-li-first {
  color: #777777;
  font-weight: bold;
}

.bn {
  color: #636363;
}
</style>
