<template>
  <div>
    <!-- 成为团长 -->
    <!-- <HeaderAd /> -->
    <ElHeader id="top" />
    <Container class="fullScreem">
      <nuxt />
    </Container>
    <ElFooter />
    <!-- <SectionBar /> -->
  </div>
</template>


<script>
// import HeaderAd from "@/components/share/HeaderAd.vue";
import Header from "@/components/share/Header.vue";
import Footer from "@/components/share/Footer.vue";
// import SectionBar from "@/components/share/SectionBar.vue";
import { Container } from "element-ui";
export default {
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  },
  components: {
    Container,
    // HeaderAd,
    ElHeader: Header,
    ElFooter: Footer
    // SectionBar
  },
  data() {
    return {
      factory: false,
      isIndex: false
    };
  },
  mounted() {
    let tmp = window.location.pathname;
    if (tmp.indexOf("factory") !== -1) {
      this.factory = true;
    }
    if (window.location.pathname === "/") {
      this.isIndex = true;
    }
  }
};
</script>
<style scoped>
.fullScreem {
  width: 100%;
  margin: 0 auto;
}
</style>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei";
}

a {
  text-decoration: none;
}

.hot {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
}

.content {
  background-color: #f2f2f2;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.topAd {
  height: 150px;
  background: url("~assets/images/leaderApply/headerADadd.jpg");
  background-position-x: center;
  min-width: 1200px;
}

.ad-ctn {
  width: 1200px;
  margin: 0 auto;
}

.word-wrapper {
  padding-top: 45px;
  float: right;
}

.word-wrapper > .ad-item {
  display: inline-block;
  font-size: 18px;
  color: white;
  line-height: 1.5;
  vertical-align: top;
}

.word-wrapper > .ad-item:last-child {
  margin: 5px 46px 0px 40px;
}
</style>
