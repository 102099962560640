import axios from "axios";

async function login(data) {
  const res = await axios.post("/api/login", data);
  return res.data;
}

async function loginOut() {
  const res = await axios.post("/api/logout");
  return res;
}

export { login, loginOut };
