import { http, axios } from "@/util/http";
// // window.fetch() 的 Polyfill
// require("whatwg-fetch");
// displayName , userName

export const state = () => ({
  user: {
    isLogin: false,
    userInfo: undefined
  },
  confirmData: {}, //下单展示的基础信息
  list: [], //删除非自提产品后的列表信息
  discount: {
    shipfee: 0,
    imb: 0,
    couponAmount: 0
  }, // 折扣
  totalPrice: 0, // 总价
  pickConfirmData: {}, //下单展示的基础信息
  picklist: [], //删除非自提产品后的列表信息
  pickDiscount: {
    shipfee: 0,
    imb: 0,
    couponAmount: 0
  }, //折扣
  pickTotalPrice: 0 //总价
});
export const mutations = {
  login(state, userInfo) {
    state.user.isLogin = true;
    state.user.userInfo = userInfo;
    http.defaults.headers.common["Authorization"] = userInfo.token;
    axios.defaults.headers.common["Authorization"] = userInfo.token;
  },
  loginout(state) {
    state.user.isLogin = false;
    state.user.userInfo = undefined;
    // http.defaults.headers.common["Authorization"] = "";
    http.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Authorization"] = "";
    window.location.href = "/login";
  },
  //初始赋值confirmData
  orderData(state, confirmData) {
    state.confirmData = confirmData;
  },
  //初始赋值imb，couponamount,shipfee等折扣信息
  getDiscount(state, discount) {
    state.discount = discount;
  },
  // 一键移除非自提产品
  deleteProduct(state) {
    state.list = [];
    for (var key in state.confirmData.teams) {
      for (var key2 in state.confirmData.teams[key].skus) {
        if (state.confirmData.teams[key].selfHasAddr) {
          state.list.push(state.confirmData.teams[key].skus[key2]);
        }
      }
    }
    let teams = state.confirmData.teams.filter(f => {
      if (f.selfHasAddr) {
        return f;
      }
    });
    for (var skukey in state.confirmData.skus) {
      if (!state.confirmData.skus[skukey].selfHasAddr) {
        delete state.confirmData.skus[skukey];
      }
    }
    state.confirmData.teams = teams;
    if (state.list.length == 0) {
      state.confirmData.imb = 0;
      state.confirmData.couponAmount = 0;
      state.confirmData.orderAmount = 0;
      state.discount.shipfee = 0;
      state.totalPrice = 0;
    }
  },
  //计算总价
  calcTotalPrice(state) {
    // debugger;
    state.totalPrice =
      state.confirmData.orderAmount +
      state.discount.shipfee -
      state.discount.imb -
      state.discount.couponAmount;
  },

  //初始赋值confirmData
  pickOrderData(state, pickConfirmData) {
    state.pickConfirmData = pickConfirmData;
  },
  //初始赋值imb，couponamount,shipfee等折扣信息
  pickGetDiscount(state, pickDiscount) {
    state.pickDiscount = pickDiscount;
  },
  // 一键移除非自提产品
  pickDeleteProduct(state) {
    var list = [];
    for (var key in state.pickConfirmData.skus) {
      if (state.pickConfirmData.skus[key].selfHasAddr) {
        list.push(state.pickConfirmData.skus[key]);
      }
    }
    var arr = JSON.parse(localStorage.getItem("pickData"));
    state.picklist = arr;
    let delelist = [];
    for (var listindex in list) {
      delelist.push(list[listindex].pickStr);
    }
    state.picklist.sd = delelist.join(",");

    let teams = state.pickConfirmData.teams.filter(f => {
      if (f.selfHasAddr) {
        return f;
      }
    });
    state.pickConfirmData.teams = teams;
    for (var index in state.pickConfirmData.teams) {
      if (!state.pickConfirmData.teams[index].selfHasAddr) {
        state.pickConfirmData.teams.splice(index, 1);
      }
    }
    if (state.picklist.sd == "") {
      state.pickConfirmData.imb = 0;
      state.pickConfirmData.couponAmount = 0;
      state.pickConfirmData.orderAmount = 0;
      state.pickDiscount.shipfee = 0;
      state.pickTotalPrice = 0;
      state.pickConfirmData.payableAmount = 0;
      state.pickConfirmData.productAmount = 0;
    }
  },
  //计算总价
  pickCalcTotalPrice(state) {
    // debugger;
    state.pickTotalPrice =
      state.pickConfirmData.productAmount +
      state.pickDiscount.shipfee -
      state.pickDiscount.imb -
      state.pickDiscount.couponAmount;
  }
};
export const actions = {
  nuxtServerInit({ commit }, { req }) {
    if (req.session && req.session.authUser) {
      commit("login", req.session.authUser);
    }
    http.defaults.headers.common["client-ip"] = req.ip || "";
    axios.defaults.headers.common["client-ip"] = req.ip || "";
    // console.log(axios.defaults.headers.common["client-ip"]) //eslint-disable-line
  },
  //保存数据
  saveOrderData({ commit }, data) {
    commit("orderData", data);
  },
  deletePdt({ commit }, data) {
    commit("deleteProduct", data);
  },
  //获取优惠信息
  discount({ commit }, data) {
    commit("getDiscount", data);
  },
  calcTotal({ commit }) {
    commit("calcTotalPrice");
  },

  //保存数据
  pickSaveOrderData({ commit }, data) {
    commit("pickOrderData", data);
  },
  pickdeletePdt({ commit }, data) {
    commit("pickDeleteProduct", data);
  },
  //获取优惠信息
  pickDiscount({ commit }, data) {
    commit("pickGetDiscount", data);
  },
  pickCalcTotal({ commit }) {
    commit("pickCalcTotalPrice");
  }
};
