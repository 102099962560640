<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
export default {
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  }
};
</script>
<style>
</style>
