import Vue from "vue";

let eventBus = new Vue();

const PRODUCTINFOCHANGECREDIT = "ProductInfo.ChangeCredit";

const ACCESSORYCONFIRM = "Accessory.Confirm";
const ACCESSORYSUBMIT = "Accessory.Submit";
const ACCESSORYCANCLE = "Accessory.Cancle";

const SWAPPRODUCTCONFIRM = "SwapProduct.Confirm";
const SWAPPRODUCTSUBMIT = "SwapProduct.Submit";
const SWAPPRODUCTCANCLE = "SwapProduct.Cancle";

const CARTNUMINPUT = "Cart.NumInput";
const SHOPCHECKED = "Shop.Checked";
const CARTCHECKED = "Cart.Checked";
const CARTACCESSORYCHECKED = "Cart.Accessory.Checked";
const ACCESSORYCHECKED = "Accessory.Checked";
const TABBARCALCULATE = "TabBar.Calculate";
const TABBARDEL = "Tab.Del";
const TABBARREMOVEUNACTIVE = "Tab.RemoveUnActive";
const TABBARPAY = "Tab.Pay";

const CARTIMHWCHECKED = "Imhw.Cart.Checked";
const CARTIMHWNUMINPUT = "Imhw.Cart.NumInput";
const TABBARIMHWDEL = "Imhw.Tab.Del";
const TABBARIMHWREMOVEUNACTIVE = "Imhw.Tab.RemoveUnActive";
const TABBARIMHWPAY = "Imhw.Tab.Pay";

const DOSEARCH = "Search.Search";
const DOSHOPSEARCH = "Search.Shop.Search";
const SEARCHPAGEPERV = "Search.Page.Perv";
const SEARCHPAGENEXT = "Search.Page.Next";
const SEARCHSWITCHTOPRODUCT = "Search.Switch.Product";
const SEARCHSWITCHTOSHOP = "Search.Switch.Shop";

const HEADERSETKEYWORD = "Header.Set.KeyWord";
const ORDERLISTRELOAD = "OrderList.Reload";

const SHOWCATRGORYBAT = "Header.Category.Show";
const HIDECATRGORYBAT = "Header.Category.Hide";

function listener(key, fn, keyPara) {
  let keyResult = keyPara ? key + keyPara : key;
  eventBus.$on(keyResult, fn);
}

function once(key, fn, keyPara) {
  let keyResult = keyPara ? key + keyPara : key;
  eventBus.$once(keyResult, fn);
}

function emit(key, fnPara, keyPara) {
  let keyResult = keyPara ? key + keyPara : key;
  eventBus.$emit(keyResult, fnPara);
}

function off(key, fnPara, keyPara) {
  let keyResult = keyPara ? key + keyPara : key;
  eventBus.$off(keyResult, fnPara);
}

export {
  eventBus,
  listener,
  emit,
  off,
  once,
  PRODUCTINFOCHANGECREDIT,
  ACCESSORYCONFIRM,
  ACCESSORYSUBMIT,
  ACCESSORYCANCLE,
  SWAPPRODUCTCONFIRM,
  SWAPPRODUCTSUBMIT,
  SWAPPRODUCTCANCLE,
  CARTNUMINPUT,
  SHOPCHECKED,
  CARTCHECKED,
  ACCESSORYCHECKED,
  TABBARCALCULATE,
  TABBARDEL,
  TABBARREMOVEUNACTIVE,
  TABBARPAY,
  DOSEARCH,
  DOSHOPSEARCH,
  HEADERSETKEYWORD,
  ORDERLISTRELOAD,
  SEARCHPAGEPERV,
  SEARCHPAGENEXT,
  CARTACCESSORYCHECKED,
  SHOWCATRGORYBAT,
  HIDECATRGORYBAT,
  SEARCHSWITCHTOPRODUCT,
  SEARCHSWITCHTOSHOP,
  CARTIMHWCHECKED,
  CARTIMHWNUMINPUT,
  TABBARIMHWDEL,
  TABBARIMHWREMOVEUNACTIVE,
  TABBARIMHWPAY
};
