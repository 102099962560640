import { get } from "@/util/request.js";

async function getAllProductNav() {
  try {
    const res = await get("getAllProductNav");
    return res.data;
  } catch (error) {
    console.log(error);
    return { headerNav: [] };
  }
}

export default getAllProductNav;
