<template>
  <div class="logo-title">
    <div v-if="status === 'memberCenter'" class="logo-title-ctn">
      <router-link to="/">
        <img src="@/assets/images/logoTitle.png" alt="阿母工业logo">
      </router-link>
      <span>{{ title }}</span>
      <div class="search"><input type="text" placeholder="搜索"><a href="javascript:void(0);"><img src="@/assets/icon/search.png" alt="搜索">搜索</a></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "我的阿母",
      status: "memberCenter" //我的状态
    };
  }
};
</script>

<style scoped>
.logo-title {
  width: 100%;
  background-color: #d7000f;
  padding-top: 10px;
  padding-bottom: 9px;
  color: white;
}

.logo-title-ctn {
  width: 1200px;
  margin: 0 auto;
}

.logo-title-ctn > span {
  font-size: 24px;
  color: white;
  display: inline-block;
  border-left: 1px solid white;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 120px;
  vertical-align: 15px;
  margin-left: 10px;
}

.logo-title-ctn .search {
  width: 385px;
  height: 41px;
  line-height: 40px;
  background-color: #e5e5e5;
  float: right;
  margin-top: 5px;
}

.logo-title-ctn .search input {
  border: none;
  /* outline: none; */
  height: 36px;
  margin-left: 2px;
  width: 247px;
  padding-left: 5px;
  right: 0px;
  bottom: 10px;
}

.logo-title-ctn .search a {
  display: inline-block;
  padding-left: 30px;
  font-size: 18px;
  color: black;
  width: 100px;
}

.logo-title-ctn .search a img {
  vertical-align: -5px;
  margin-right: 15px;
}
</style>
