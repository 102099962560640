const env = process.env.NODE_ENV;

function getBaseUrl() {
  let baseUrl;
  if (env === "development") {
    baseUrl = "http://10.10.10.80:9999/";
  } else {
    baseUrl = "https://api.imow.cn/";
  }
  return baseUrl;
}
function getImgUrl() {
  let uploadImgUrl;
  if (env === "development") {
    uploadImgUrl = "http://imow-ht-test.oss-cn-hangzhou.aliyuncs.com/";
  } else {
    uploadImgUrl = "https://imow-upload.oss-cn-hangzhou.aliyuncs.com/";
  }
  return uploadImgUrl;
}

function getWsUrl() {
  let wsUrl;
  if (env === "development") {
    wsUrl = "ws://10.10.10.80:55555/";
  } else {
    wsUrl = "wss://api.imow.cn:55555/";
  }
  return wsUrl;
}

export { getBaseUrl, getImgUrl, getWsUrl };
